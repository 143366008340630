<template>
  <div class="dashboard-container" v-loading="loading">
    <template>
      <div>
        <div>
          <div class="title1" style="font-size:16px;">
            当前企业账户还有
            <span style="color:red;">{{ level_duetimeData }}</span>天到期
          </div>
        </div>
        <div class="statistics df jcsb">
          <div class="statistics-left">
            <div class="h150">
              <div class="df">
                <div class="data-card-left">
                  <img src="../../assets/i1.png" alt />
                </div>
                <div>
                  <div class="data-card-right-text">待发货订单</div>
                  <div class="cs" style="color:#40bf96">{{ (dashBoardData.wait_ship_order_num || 0) }}</div>
                </div>
              </div>
            </div>
            <div style="height:1px;background:#F5F6F9;margin:0 auto;"></div>
            <div class="h150" style="padding-top:45px;">
              <div class="df">
                <div class="data-card-left">
                  <img src="../../assets/shouhou.png" alt />
                </div>
                <div>
                  <div class="data-card-right-text">售后订单</div>
                  <div class="cs" style="color:#6f70c8">{{ (dashBoardData.after_sale_order_num || 0) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-right" style="width:66%;">
            <div class="h143" style="margin-bottom:14px;width:100%">
              <div class="df">
                <div class="data-card-left">
                  <img src="../../assets/jiaoyi.png" alt />
                </div>
                <div>
                  <div class="data-card-right-text">昨日交易额</div>
                  <div class="cs" style="color:#f76856">{{ (dashBoardData.yesterday_pay_order_total || 0) }}</div>
                </div>
              </div>
            </div>
            <div class="df jcsb">
              <div class="h143">
                <div class="df">
                  <div class="data-card-left">
                    <img src="../../assets/dingdan.png" alt />
                  </div>
                  <div>
                    <div class="data-card-right-text">昨日订单</div>
                    <div class="cs" style="color:#dcae3f">{{ (dashBoardData.yesterday_all_order_num || 0) }}</div>
                  </div>
                </div>
              </div>
              <div class="h143">
                <div class="df">
                  <div class="data-card-left">
                    <img src="../../assets/wanchengdingdan.png" alt />
                  </div>
                  <div>
                    <div class="data-card-right-text">昨日完成订单</div>
                    <div class="cs" style="color:#1a43a9">{{ (dashBoardData.yesterday_complete_order_num || 0) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="quickLink">
        <div class="title1" style="font-size:16px;">
          当前企业账户还有
          <span style="color:red;">{{ level_duetimeData }}</span>天到期
        </div>
      </div> -->

      <!-- <el-row :gutter="20" type="flex" justify="space-around">
        <el-col :span="10" style="padding-right: 0">
          <el-card style="margin-bottom: 10px" shadow="hover">
            <router-link
              to="/order/order-lista?type=1"
              tag="div"
              style="cursor: pointer;"
            >
              <div class="header row no-gutters align-items-center">
                <div class="col-auto">
                  <img src="../../assets/i1.png" alt=""/>
                </div>
                <div class="cvc col">
                  待发货订单
                </div>
              </div>
              <div class="cs" style="color:rgba(64, 191, 150, 1)">
                {{ dashBoardData.wait_ship_order_num || 0 }}
              </div>
            </router-link>
          </el-card>

          <el-card shadow="hover">
            <router-link
              to="/order/refund-list?type=3"
              tag="div"
              style="cursor: pointer;"
            >
              <div class="header row no-gutters align-items-center">
                <div class="col-auto">
                  <img src="../../assets/shouhou.png" alt=""/>
                </div>
                <div class="cvc col">
                  待售后
                </div>
              </div>
              <div
                class="cs"
                style="color:rgba(111, 112, 200, 1)"
              >
                {{ dashBoardData.after_sale_order_num || 0 }}
              </div>
            </router-link>
          </el-card>
        </el-col>

        <el-col :span="14">
          <el-card style="margin-bottom: 10px" shadow="hover">
            <div class="header row no-gutters align-items-center">
              <div class="col-auto">
                <img src="../../assets/jiaoyi.png" alt=""/>
              </div>
              <div class="cvc col">
                昨日交易额
              </div>
            </div>
            <div class="cs" style="color:rgba(247, 103, 86, 1)">
              {{ dashBoardData.yesterday_pay_order_total || 0 }}
            </div>
          </el-card>

          <el-row :gutter="20" type="flex" justify="space-around">
            <el-col :span="12" style="padding-right: 0">
              <el-card shadow="hover">
                <router-link
                  to="/order/order-lista?type=3"
                  tag="div"
                  style="cursor: pointer;"
                >
                  <div class="header row no-gutters align-items-center">
                    <div class="col-auto">
                      <img src="../../assets/dingdan.png" alt=""/>
                    </div>
                    <div class="cvc col">
                      <span>昨日订单总数</span>
                    </div>
                  </div>

                  <div class="cs" style="color:rgba(220, 174, 63, 1)">
                    {{ dashBoardData.yesterday_all_order_num || 0 }}
                  </div>
                </router-link>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card shadow="hover">
                <router-link
                  to="/order/order-lista?type=4"
                  tag="div"
                  style="cursor: pointer;"
                >
                  <div class="header row no-gutters align-items-center">
                    <div class="col-auto">
                      <img src="../../assets/wanchengdingdan.png" alt=""/>
                    </div>
                    <div class="cvc col">
                      昨日完成订单数
                    </div>
                  </div>
                  <div class="cs" style="color:rgba(26, 67, 169, 1)">
                    {{ dashBoardData.yesterday_complete_order_num || 0 }}
                  </div>
                </router-link>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row> -->

      <div class="quickLink">
        <div class="title">运营快捷入口</div>
        <div class="linkBox">
          <router-link to="/finance/enterprise-overview">
            <img src="../../assets/caiwuguanli.png"/>财务管理
          </router-link>
          <router-link to="/application/shop-management">
            <img src="../../assets/shangjiaguanli.png"/>商家管理
          </router-link>
          <router-link to="/application/czklist">
            <img src="../../assets/yingyingzhongxin.png"/>应用中心
          </router-link>
          <router-link to="/compromote">
            <img src="../../assets/qiyeshengji.png"/>企业升级
          </router-link>
          <router-link to="/order/order-lista">
            <img src="../../assets/dingdanguanli.png"/>订单管理
          </router-link>
        </div>
      </div>
      <div class="app">
        <div class="title">营销应用</div>
        <ul>
          <li @click="toChildShopList()" style="cursor:pointer">
            <img src="../../assets/shangcheng.png"/>
            <h4>现金商城</h4>
            <div class="info">满足企业福利及企业内部审批采购场景需求</div>
            <div class="data">
              <p>可创建：{{ market.canCreateStoreNum || 0 }}</p>
              <p>已创建：{{ market.hasStoreNum || 0 }}</p>
            </div>
          </li>
          <li @click="toCardComboList()" style="cursor:pointer" v-if="isShowCombo">
            <img src="../../assets/shangcheng.png" />
            <h4>套餐卡商城</h4>
            <div class="info">满足企业单品组合套餐方案，多套餐选一卡册业务场景</div>
            <div class="data">
              <p>可创建：不限</p>
              <p>已创建：{{ market.comboShopNum || 0 }}</p>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import * as API_Dashboard from "@/api/dashboard";
import * as API_shop from "@/api/shop";
import * as API_SiteMenu from "@/api/siteMenu";
// import * as API_trafficStatistics from '@/api/trafficStatistics';

export default {
  name: "home",

  data() {
    return {
      sesalChart: "",
      /** 加载中*/
      loading: false,

      /** 商家基本信息 */
      shop_info: this.$store.getters.shopInfo,
      /** 首页统计天数*/
      level_duetimeData: "",
      /** 首页统计数据*/
      dashBoardData: {},

      /** 商城公告*/
      shop_announcement: [],

      /** 平台联系方式*/
      concat: null,

      /** 商家图标*/
      fileList: [],

      /** 视图高度*/
      tableHeight:
        (document.body.clientHeight - 84 - 80 - 80 - 20 - 20 - 4) / 2,

      /** 当前商城公告名称 */
      currentName: "",

      /** 当前商城公告内容 */
      currentContent: "",

      /** 是否显示商城公告 默认不显示 */
      isShowArticle: false,
      market: {
        canCreateStoreNum: 0,
        hasStoreNum: 0,
        canCreatePackageNum: 0,
        hasPackageNum: 0,
        hasSupplier: 0,
        comboShopNum: 0
      },
      comboShop_num: 0,
      isShowCombo: false
    };
  },
  mounted() {
    // console.log(this.$url,'$url')
    /** 首页统计天数*/
    this.GET_sellerShops();
    /** 获取首页统计信息 */
    this.GET_DashBoard();

    /** 获取首页商城公告 */
    this.GET_Notice();

    /** 获取首页联系方式 */
    this.GET_Concate();
    /**营销应用*/
    this.getLv();
    this.GET_List();
    window.onresize = this.countTableHeight;
  },
  computed: {
    /** 用户信息 */
    user_info() {
      return this.$store.getters.user;
    }
  },
  methods: {
    // 获取当前企业的等级是否拥有套餐卡商城应用
    GET_List() {
      API_SiteMenu.getAllList().then(res => {
        res.forEach(item => {
          if (item.is_level === 1) {
            this.isShowCombo = item.has_package_store === 1;
          }
        });
      });
    },
    /** 窗口缩放时计算table高度 */
    countTableHeight() {
      this.tableHeight =
        (document.body.clientHeight - 84 - 80 - 80 - 20 - 20 - 4) / 2;
    },
    /** 获得首页统计信息*/
    GET_sellerShops() {
      this.loading = true;
      API_shop.sellerShops().then(response => {
        this.loading = false;
        var date1 = new Date(); //开始时间
        var date3 = response.level_duetime * 1000 - date1.getTime(); //时间差的毫秒数zhi
        this.level_duetimeData =
          Math.floor(date3 / (24 * 3600 * 1000)) > 0
            ? Math.floor(date3 / (24 * 3600 * 1000))
            : 0;
      });
    },

    /** 获得首页统计信息*/
    GET_DashBoard() {
      this.loading = true;
      API_Dashboard.getDashboardData().then(response => {
        this.loading = false;
        this.dashBoardData = response;
      });
    },
    /** 搜索企业当前等级信息*/
    getLv() {
      API_Dashboard.getLv().then(response => {
        this.market = response;
      });
    },
    /** 获取首页 商城公告 */
    GET_Notice() {
      this.loading = true;
      const category_type = "NOTICE";
      API_Dashboard.getNotice(category_type, {}).then(response => {
        this.loading = false;
        this.shop_announcement = response;
      });
    },

    /** 获取首页 平台联系方式 */
    GET_Concate() {
      this.loading = true;
      API_Dashboard.getConcate({ position: "CONTACT_INFORMATION" }).then(
        response => {
          this.loading = false;
          this.concat = response;
        }
      );
    },
    /** 跳转企业下子商城*/
    toChildShopList() {
      this.$router.push({ path: "/application/shop-management" });
    },
    /** 跳转套餐卡商城*/
    toCardComboList() {
      this.$router.push({
        path: "/application/shop-management",
        query: { combo: "comboCardShop" }
      });
    }
    // /** 店铺LOGO上传 */
    // toChangeShopIcon() {
    //   this.$refs.uploadBtn.click();
    // },

    // /** 图片上传之前的校验 */
    // handleShopLogoBefore(file) {
    //   return new Promise((resolve, reject) => {
    //     let reader = new FileReader();
    //     reader.onload = event => {
    //       let image = new Image();
    //       image.onload = () => {
    //         let width = image.width;
    //         let height = image.height;
    //         if (width > 300 || width < 200) {
    //           this.$message.error("图片宽度必须在200~300之间！");
    //           reject();
    //         }
    //         if (height > 100 || height < 50) {
    //           this.$message.error("图片高度必须在50~100之间！");
    //           reject();
    //         }
    //         resolve();
    //       };
    //       image.src = event.target.result;
    //     };
    //     reader.readAsDataURL(file);
    //   });
    // },

    // /** 上传成功后的钩子 更换图片 置空存储数组*/
    // uploaded(response) {
    //   this.shop_info.shop_logo = response.url;
    //   this.fileList = [];
    //   API_shop.updateShopLogo({ logo: response.url }).then(() => {
    //     this.$message.success("店铺LOGO修改成功");
    //     this.$store.dispatch("getShopInfoAction");
    //   });
    // },

    // /** 跳转商品列表*/
    // toGoodsManager(goodsStatus) {
    //   this.$router.push({
    //     path: "/goods/goods-list",
    //     query: { market_enable: goodsStatus }
    //   });
    // },

    // /** 跳转买家留言 待回复的咨询 */
    // toConsumerMsg() {
    //   this.$router.push({
    //     path: "/customer/consultation",
    //     query: { reply_status: 0 }
    //   });
    // },

    // /** 跳转订单列表*/
    // toOrderList(orderStatus) {
    //   this.$router.push({
    //     path: "/order/order-list",
    //     query: { order_status: orderStatus }
    //   });
    // },

    // /** 跳转维权订单*/
    // toRefundOrderList() {
    //   this.$router.push({ path: "/order/refund-list" });
    // },

    // /** 显示商城公告 */
    // showArticleContext(row) {
    //   this.currentName = row.article_name;
    //   this.currentContent = row.content;
    //   this.isShowArticle = true;
    // }
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  .header {
    .col-auto {
      img {
        width: 26px;
        height: 26px;
      }
    }

    .col {
      padding-left: 12px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .cs {
    padding-top: 32px;
    transform: scaleY(1.1);
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
  }
}

.box span {
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
}

.box > div {
  display: inline-block;
}

.dashboard-container {
  height: 100%;
}

/deep/ .el-row {
  position: relative;
}

.el-col {
  border-radius: 4px;
}

/*商家基本信息*/
div.grid-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  /*logo*/
  .logo {
    flex-grow: 1;
    padding: 15px;
    position: relative;
    box-sizing: content-box;

    div.shop-img-icon {
      width: 200px;

      &:hover span.to-change-shop-icon {
        display: block;
      }

      span.to-change-shop-icon {
        display: none;
        position: absolute;
        left: 16px;
        bottom: 20px;
        z-index: 10;
        width: 200px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        font-weight: normal;
        background: rgba(33, 33, 33, 0.6);
        color: #fff;
        cursor: pointer;
      }

      img {
        width: 200px;
        height: 80px;
        cursor: pointer;
        border: 1px solid #999;
      }
    }
  }

  /*商家信息*/
  .shop-info {
    flex-grow: 40;
    padding-top: 30px;
    /*商家主营信息*/
    .shop-info-basic {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      span:first-child {
        color: #333;
        font: 18px/36px "microsoft yahei";
        letter-spacing: normal;
        vertical-align: bottom;
        word-spacing: normal;
      }

      span:last-child {
        color: #999;
        font-size: 12px;
        letter-spacing: normal;
        line-height: 28px;
        margin-left: 12px;
        vertical-align: bottom;
        word-spacing: normal;
      }
    }

    /*卖家信用*/
    .shop-info-credit {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      /deep/ span {
        display: inline-block;
        margin-left: 0 !important;
        color: #999;
        font-size: 12px;
        letter-spacing: normal;
        line-height: 28px;
        vertical-align: bottom;
        word-spacing: normal;
      }

      .shop-icon {
        margin: 0 40px;
      }
    }
  }

  /*动态评分*/
  .score {
    flex-grow: 2.5;
    text-align: right;
    padding: 5px 20px;

    p {
      margin: 0;
      text-align: left;
      border-bottom: 1px dotted #e7e7e7;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding-bottom: 5px;
    }

    div.active-score {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      margin-top: 5px;

      div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;

        span:first-child {
          color: #333;
          display: inline-block;
          font-size: 14px;
          text-align: center;
          padding: 5px 0px;
          font-weight: bold;
        }

        span:last-child {
          display: inline-block;
          font-size: 14px;
          text-align: center;
          padding: 5px 0px;
          color: #da542e;
        }
      }
    }
  }
}

/* 店铺商品提示/交易订单提示*/
h1 {
  border-left: 3px solid #28b779;
  font: 16px/18px "microsoft yahei";
  height: 18px;
  margin-bottom: 4px;
  padding-left: 6px;
  margin-left: 17px;
}

/*副标题*/
h2 {
  color: #aaa;
  font: 12px/16px "microsoft yahei";
  margin: 10px 0;
}

/* 店铺/交易提示内容 */
.store-index-content {
  margin: 20px 0;
  overflow: hidden;

  .store-rompt {
    clear: both;
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    cursor: pointer;
  }

  /** 标签 */
  .el-tag {
    cursor: pointer;
  }
}

/*商城公告*/
.store-bulletin {
  color: #aaa;
  cursor: pointer;
  font: 12px/16px "microsoft yahei";
  margin: 10px 0;

  &:hover a {
    color: #337ab7;
  }
}

/*平台联系方式*/
.platform-concate {
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    color: #aaa;
    font: 12px/16px "microsoft yahei";
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    span {
      display: inline-block;

      /deep/ p {
        margin: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.dashboard-container {
  padding-top: 1px;
  padding-bottom: 1px;
}

.tip {
  margin: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #eee;
  line-height: 36px;
  padding-left: 20px;
}

.tip span {
  color: red;
}

.link /deep/ .el-card__body {
  min-height: 0;
}

.order {
  overflow: hidden;
}

.order .orderLeft {
  float: left;
  width: 150px;
  padding: 20px;
  border-right: 1px solid #eee;
}

.order .orderRight {
  float: right;
  width: calc(100% - 160px);
}

.orderBox {
  margin-bottom: 40px;
}

.orderBox h2 {
  font-size: 14px;
  color: #999;
  line-height: 20px;
  margin: 0;
}

.orderBox h3 {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
}

.orderBox p {
  font-size: 12px;
  color: #ccc;
  line-height: 20px;
  margin: 0;
}

.orderBox span.red {
  color: rgb(240, 72, 68);
  background: url(../../assets/images/icon9.svg) no-repeat left center;
  padding-left: 10px;
  margin-right: 5px;
}

.orderBox span.green {
  color: rgb(26, 188, 156);
  background: url(../../assets/images/icon8.svg) no-repeat left center;
  padding-left: 10px;
  margin-right: 5px;
}

.orderTab {
  padding-right: 20px;
}

.orderTab h4 {
  line-height: 32px;
  margin: 0;
  text-indent: 40px;
}

.tabBtn {
  float: right;
}

.tabBtn span {
  margin-right: 10px;
  cursor: pointer;
}

.tabBtn span.active {
  color: rgb(26, 188, 156);
}

.boxCard {
  border-radius: 10px;
  background: #fff;
  padding-left: 36px;
}

.boxCard .hr {
  width: 552px;
  background: #f5f6f9;
  height: 1px;
  margin: 5px auto 5px;
}

.boxCard .box {
  padding-bottom: 40px;
  padding-top: 40px;
}

.boxCard .box h2 {
  padding-top: 42px;
  padding-left: 40px;
  font-size: 30px;
  color: #393c41;
  font-weight: 600;
  line-height: 48px;
  margin: 0;
}

.boxCard .box h2 img {
  width: 48px;
  height: 48px;
  margin-right: 22px;
  margin-top: -4px;
}

.quickLink {
  margin: 20px 0;
}

.title1 {
  color: #393c41;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  padding-left: 10px;
}

.title {
  color: #393c41;
  font-size: 17px;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 14px;
  position: relative;
  padding-left: 10px;
}

.title:before {
  content: " ";
  display: block;
  width: 4px;
  height: 17px;
  background: #1a43a9;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}

.linkBox {
  font-size: 0;
  line-height: 0;
}

.linkBox a {
  display: inline-block;
  width: 171px;
  height: 70px;
  border-radius: 5px;
  line-height: 70px;
  color: #393c41;
  margin-right: 10px;
  background: #fff;
  text-align: center;
  font-size: 14px;
}

.linkBox a:last-child {
  margin-right: 0;
}

.linkBox a img {
  margin-right: 9px;
  margin-top: -4px;
}

.app ul {
  font-size: 0;
  line-height: 0;
  list-style: none;
  padding: 0;
}

.app ul li {
  display: inline-block;
  width: calc((100% - 40px) / 5);
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  height: 299px;
  margin-right: 9.5px;
  position: relative;
}

.app ul li:last-child {
  margin-right: 0;
}

.app ul li img {
  display: block;
  margin: auto;
  margin-bottom: 7px;
  width: 50%;
}

.app ul li h4 {
  font-size: 16px;
  color: #393c41;
  margin-top: 16px;
}

.app ul li .info {
  text-align: left;
  font-size: 14px;
  color: #585c66;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-top: 16px;
  height: 54px;
}

.app ul li p {
  display: block;
  color: #333333;
  line-height: 9px;
}

.app ul li .data {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 14px;
  font-weight: 600;
}
.df {
  display: flex;
}
.jcsb {
  justify-content: space-between;
}
.h150 {
  height: 150px;
  padding: 45px 0;
  background-color: #fff;
  border-radius: 10px;
}
.h143 {
  width: 49.2%;
  height: 143px;
  padding: 45px 30px;
  background-color: #fff;
  border-radius: 10px;
}
.statistics-left {
  width: 33%;
  box-sizing: border-box;
  padding: 0 30px;
  border-radius: 10px;
  background-color: #fff;
}
  .data-card-left{
    margin-right: 16px;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .data-card-right-text {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1;
    font-weight: 800;
    color: #393C41;
    font-family: PingFang;
  }
  .cs {
    height: 38px;
    line-height: 1;
    font-size: 30px;
    font-family: DINCond;
    font-weight: 900;
  }
</style>
